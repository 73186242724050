import * as React from 'react'

import { Grid } from '@mui/material'

import { GraphSection } from './graph_section'
import {
  AppContainer,
  ButtonsContainer,
  EarningsBalance,
  GRAPH_TYPES,
  GraphType,
  GraphTypeButtons,
  GraphUnitButtons,
  MainBalance,
  RangeButtons,
  UserAssetsDisplay,
  VariationBalance,
} from '../../components'
import {
  computePortfolioBalances,
  getPortfolioGraphData,
  hasApy,
  withSymbols,
} from '../../services'

import type {
  AssetsPricesData,
  BaseApysData,
  MarketAssetsData,
  ShortRange,
  UserOperation,
} from '../../queries'

const graphHeight = 280

type PortfolioDisplayProps = {
  loading: boolean
  assetsData?: MarketAssetsData
  pricesData?: AssetsPricesData
  baseApysData?: BaseApysData
  operations: UserOperation[]
  range: ShortRange
  setRange: (newRange: ShortRange) => void
  graphUnits: string[]
  graphUnit: string
  setGraphUnit: (newGraphUnit: string) => void
  fomoText: React.ReactNode
}

export const PortfolioDisplay = ({
  loading,
  assetsData,
  pricesData,
  baseApysData,
  operations,
  range,
  setRange,
  graphUnits,
  graphUnit,
  setGraphUnit,
  fomoText,
}: PortfolioDisplayProps) => {
  const marketAssets = assetsData?.marketAssets || []
  const assetsOperations = withSymbols(operations, marketAssets.map((asset) => asset.symbol))

  const [graphType, setGraphType] = React.useState<GraphType>('COMPOSITION')

  const usdPrices = pricesData?.assetsPrices || []
  const baseApys = baseApysData?.baseApys || []

  const hasNoOperations = (!loading && assetsOperations.length === 0)

  const portfolioBalances = computePortfolioBalances(marketAssets, assetsOperations, usdPrices)
  const portfolioGraphData = getPortfolioGraphData(portfolioBalances)
  const totalEarnedAmount = portfolioBalances.reduce((acc, portfolio) => (
    acc += portfolio.earnedAmount * (portfolio.priceGraphData.lastValue || 0)
  ), 0)

  return (
    <Grid
      container
      spacing={3}
      pb={12}
    >
      <MainBalance
        loading={loading}
        currency={graphUnit}
        amount={portfolioGraphData.lastValue}
      />
      {hasApy(baseApys) && graphType === 'COMPOSITION' ? (
        <EarningsBalance
          loading={loading}
          currency={graphUnit}
          amount={totalEarnedAmount}
        />
      ) : (
        <VariationBalance
          loading={loading}
          currency={graphUnit}
          range={range}
          amount={portfolioGraphData.variation}
        />
      )}
      <ButtonsContainer>
        <GraphTypeButtons
          disabled={loading}
          keys={GRAPH_TYPES}
          graphType={graphType}
          setGraphType={setGraphType}
          containerProps={{ xs: true, justifyContent: 'flex-end' }}
        />
        <GraphUnitButtons
          disabled={loading}
          keys={graphUnits}
          graphUnit={graphUnit}
          setGraphUnit={setGraphUnit}
          containerProps={{ justifyContent: 'flex-end' }}
        />
        <RangeButtons
          disabled={loading || hasNoOperations || graphType === 'COMPOSITION'}
          keys={['24h', '7d']}
          range={range}
          setRange={setRange}
          containerProps={{ justifyContent: 'flex-end' }}
        />
      </ButtonsContainer>
      <AppContainer sx={{ height: graphHeight }}>
        {hasNoOperations ? (
          fomoText
        ) : (
          <GraphSection
            graphType={graphType}
            graphUnit={graphUnit}
            portfolioBalances={portfolioBalances}
            portfolioGraphData={portfolioGraphData}
          />
        )}
      </AppContainer>
      <UserAssetsDisplay
        graphUnit={graphUnit}
        marketAssets={marketAssets}
        portfolioBalances={portfolioBalances}
        baseApys={baseApys}
      />
    </Grid>
  )
}
